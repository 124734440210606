import React from "react"
import {Container, Row, Col, Media} from "reactstrap"
import {StaticQuery, graphql} from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"
import {colors, fonts} from "../../theme.js"

const Image = () => (
  <StaticQuery
    query={graphql`{
  placeholderImage: file(relativePath: {eq: "blogavatar.png"}) {
    childImageSharp {
      gatsbyImageData(height: 100, quality: 100, layout: FIXED)
    }
  }
}
`}
    render={data => (
      <GatsbyImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        alt="Spark Files" />
    )}
  />
)

const Bio = () => (
  <Container fluid={true} className="jointheform py-3">
    <Row>
      <Col sm="12" className="muted">
        <section>
          <Media
            className="shadow rounded p-2"
            style={{
              background: colors.meatChar,
              maxWidth: 500,
              margin: `auto`,
            }}>
            <BlogAvatar>
              <Image />
            </BlogAvatar>
            <Media body>
              <h5 className="mt-0 mb-0" style={{fontFamily: `Jost`}}>
                <span style={{color: `#fff`}}>
                  <b>Author:</b>
                </span>{" "}
		<AuthorLink href="https://sparkfiles.net/about/">
                  Word Wizard
                </AuthorLink>
              </h5>
              <small style={{color: `#fff`}}>
                <i>
                  By words we learn thoughts, and by thoughts we learn life.
                </i>{" "}
                <span style={{color: colors.meatRare}}>
                  <br />
                  <b>
                    Spark <u>Files</u>.
                  </b>
                </span>
                <br /> <b>Subscribe to stay connected.</b>
              </small>
            </Media>
          </Media>
        </section>
      </Col>
    </Row>
  </Container>
)

export default Bio

const BlogAvatar = styled.div`
  margin-right: 1rem;
  align-self: center;
  img {
    border-radius: 50%;
    border: 5px solid ${colors.meatRare};
    background: ${colors.meatRare};
  }
`
const AuthorLink = styled.a`
  color: ${colors.meatFire} !important;
  &:hover {
    color: ${colors.meatRare} !important;
  }
`
