import React from "react"
import {
  Card,
  CardTitle,
  CardText,
  CardBody,
  Form,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import {StaticQuery, graphql, Link} from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"
import {colors, fonts} from "../../theme.js"
import IcoMoon from "react-icomoon"

const Image = () => (
  <StaticQuery
    query={graphql`{
  placeholderImage: file(relativePath: {eq: "avatar.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 200
        quality: 100
        tracedSVGOptions: {background: "#290849", color: "#ffd524"}
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
}
`}
    render={data => (
      <GatsbyImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        alt="Spark Files" />
    )}
  />
)

const Sidebar = () => (
  <aside>
    <section>
      <Widget>
        <CardBody>
          <BioAvatar>
            <Image />
          </BioAvatar>
          <CardTitle className="text-center text-white mb-0">
            <h5>
              <span style={{color: colors.meatFire}}>Spark</span>{" "}
              <span style={{color: colors.meatRare}}>Files</span>
            </h5>
          </CardTitle>
          <BioText>
            <small>
              Exploring{" "}
              <span style={{color: colors.meatRare}}>
                <b>etymology</b>
              </span>
              , phrases and <i>customs</i>.<br />
              <b>Word wizardry.</b>
            </small>
          </BioText>
        </CardBody>
      </Widget>
      <Widget>
        <CardBody>
          <CardTitle className="text-center text-white mb-3">
            <h5>
              Get updates when we add new words and customs
              <br />
              <span style={{color: colors.meatRare}}>SUBSCRIBE:</span>
            </h5>
          </CardTitle>
          <Form
            className="text-center"
            action="https://www.getdrip.com/forms/XXX/submissions"
            method="post"
            data-drip-embedded-form="XXX">
            <FormGroup>
              <Input
                type="email"
                name="fields[email]"
                id="drip-email"
                aria-label="Email"
                placeholder="Your email address.."
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="select"
                name="fields[language]"
                id="drip-language"
                aria-label="Selection">
                <option disabled="disabled" selected>
                  Your interest...
                </option>
                <option value="weightloss">Etymology (Word origins)</option>
                <option value="muscle">Culture and customs</option>
              </Input>
            </FormGroup>
            <JoinButton
              data-drip-attribute="sign-up-button"
              className="btn text-white text-uppercase">
              Subscribe
            </JoinButton>
          </Form>
        </CardBody>
      </Widget>
      {/*
      <WidgetAlt>
        <CardBody>
          <CardText>
            <a href="/rss.xml">
              <IcoMoon icon="rss" style={{fontSize: `2rem`}} />
            </a>
          </CardText>
        </CardBody>
	</WidgetAlt>
	*/}
      <Widget>
        <CardBody>
          <CardTitle>
            <h5 style={{color: colors.meatRare}}>Top Articles:</h5>
          </CardTitle>
          <ListGroup>
            <MenuItem tag="a" href="/foot-whats-special-12-inches/">
              The Origin Of Feet (Measurement)
            </MenuItem>
            <MenuItem tag="a" href="/origin-sir-maam/">
              When Did People Start Saying 'Sir' And 'Maam'?
            </MenuItem>
            <MenuItem tag="a" href="/illinois/">
              How Did Illinois Get Its Name?
            </MenuItem>
            <MenuItem tag="a" href="/pounds-lbs-abbreviations-origin/">
              Why We Call Pounds 'Lbs'
            </MenuItem>
          </ListGroup>
        </CardBody>
      </Widget>
      <Widget>
        <CardBody>
          <CardTitle>
            <h5 style={{color: colors.meatRare}}>Grow Your Mind:</h5>
          </CardTitle>
          <ListGroup>
            <MenuItem tag="a" href="/word-origin-books/">
              <IcoMoon icon="book" style={{fontSize: `1rem`}} /> Word Origin
              Books
            </MenuItem>
          </ListGroup>
        </CardBody>
      </Widget>
    </section>
    <section />
  </aside>
)

export default Sidebar

const Widget = styled(Card)`
  background-color: ${colors.meatExtrachar} !important;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  //min-height: 200px;
  text-align: center;
`
const WidgetAlt = styled(Card)`
  background-color: ${colors.meatExtrachar} !important;
  border-radius: 6px;
  color: #fff;
  margin-bottom: 0.5rem;
  text-align: center;
  a {
    color: ${colors.meatRare} !important;
    &:hover {
      color: ${colors.secondaryColor} !important;
    }
  }
`
const SidebarLink = styled(Link)`
  color: #fff !important;
  &:hover {
    color: ${colors.secondaryColor};
  }
`
const BioAvatar = styled.div`
  margin: auto;
  margin-bottom: 15px;
  max-width: 200px;
  align-self: center;
  img {
    background-color: ${colors.meatRare};
    border-radius: 50%;
    border: 8px solid ${colors.meatRare};
  }
`
const BioText = styled(CardText)`
  color: #fff;
  line-height: 1.2;
`
const JoinButton = styled.button`
  background-color: ${colors.meatFire} !important;
  transition: 0.5s;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
  font-weight: 700 !important;
  &:hover {
    background-color: ${colors.meatRare} !important;
  }
`
const MenuItem = styled(ListGroupItem)`
  &:hover {
    background: ${colors.meatFire} !important;
    color: ${colors.meatChar} !important;
  }
`
