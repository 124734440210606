/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql} from "gatsby"

import Header from "./header"
import Footer from "./footer"
import FooterSM from "./sm-footer"
import JoinThe from "./jointhe"
import Bio from "./bio"

import styled from "styled-components"

import "../styles/styles.scss"

import {colors, fonts} from "../../theme.js"

const BlogLayout = ({children}) => (
  <StaticQuery
    query={graphql`
      query BlogSiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <LayoutGradient>{children}</LayoutGradient>
        <JoinThe />
        <Bio />
        <FooterSM />
        <Footer />
      </>
    )}
  />
)

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlogLayout

const LayoutGradient = styled.main`
  background-image: linear-gradient(316deg, ${colors.meatMedium}, #131313);
  padding-top: 20px;
`
